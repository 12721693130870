import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

export default function() {
  const button = document.querySelector('.l-news__selection__button');
  const offer = document.querySelector('.l-news__offer');
  const inner = document.querySelector('.l-news__offer__inner');  // スクロールする要素
  const close = document.querySelector('.l-news__offer__close');

  function show() {
    offer.classList.add('-show');
    disableBodyScroll(inner);
  }

  function hide() {
    offer.classList.remove('-show');
    document.documentElement.style.scrollBehavior = 'auto';  // iOSでモーダルを閉じるときに、bodyが高速スクロールして戻ってくるのを一瞬で戻すようにする
    enableBodyScroll(inner);
    setTimeout(() => document.documentElement.style.scrollBehavior = '');
  }

  button.addEventListener('click', show);
  close.addEventListener('click', hide);
  offer.addEventListener('click', e => {
    if (!e.target.closest('.l-news__offer__inner')) {
      hide();
    }
  });
}