import Swiper, {Navigation} from 'swiper';
// import {Navigation} from 'swiper/modules';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

export default function() {
  const modal = document.querySelector('.l-ourBusinessModal');
  const container = document.querySelector('.l-ourBusinessModal__businesses');
  const inner = document.querySelector('.l-ourBusinessModal__business__inner');  // スクロールする要素
  const close = document.querySelector('.l-ourBusinessModal__close');
  const links = document.querySelectorAll('.l-ourBusiness__body__business__link');

  for (let link of links) {
    link.addEventListener('click', () => show(link.dataset.modalIndex));
  }
  close.addEventListener('click', hide);
  modal.addEventListener('click', e => {
    if (!e.target.closest('.l-ourBusinessModal__inner')) {
      hide();
    }
  });

  let swiper = null;
  function show(initialIndex) {
    modal.classList.add('-show');
    disableBodyScroll(inner, {
      allowTouchMove: el => el.closest('.l-ourBusinessModal__business__inner')  // iOSで２枚目以降のスライドでスクロールできない問題に対応
    });
    swiper = buildSlider(initialIndex);
  }

  function hide() {
    modal.classList.remove('-show');
    document.documentElement.style.scrollBehavior = 'auto';  // iOSでモーダルを閉じるときに、bodyが高速スクロールして戻ってくるのを一瞬で戻すようにする
    enableBodyScroll(inner);
    setTimeout(() => document.documentElement.style.scrollBehavior = '');
    if (swiper) swiper.destroy();
    swiper = null;
  }

  function buildSlider(initialIndex) {
    return new Swiper(container, {
      modules: [Navigation],
      loop: true,
      slidesPerView: 1,
      spaceBetween: 10,
      speed: 500,
      initialSlide: Number(initialIndex),
      navigation: {
        nextEl: '.l-ourBusinessModal__navigation.-next',
        prevEl: '.l-ourBusinessModal__navigation.-prev',
      },
      breakpoints: {
      },
    });
  }
}
