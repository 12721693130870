let initial = true;

const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  if (initial) {
    document.documentElement.style.setProperty('--vhInit', `${vh}px`);
    initial = false;
  }
};
 
setVh();
window.addEventListener('load', setVh);
window.addEventListener('resize', setVh);