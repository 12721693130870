import './common/calc100vh';
import './common/matchMedia';
import './common/inview';
import './common/enableGnav';
import './common/trackNavigation';

import './page/top';
import './page/career';
import './page/students';
import './page/group';
import './page/newsDetail';