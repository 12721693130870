import Swiper, {Autoplay, Navigation} from 'swiper';
// import {Autoplay, Navigation} from 'swiper/modules';
import buildMoviesSlide from '../module/buildMoviesSlide';

if (document.body.classList.contains('r6group')) {
  window.addEventListener('DOMContentLoaded', () => {
    buildMoviesSlide('.r6group__movies__container', '.r6group__movies__pagination');
    enableTyping();
    buildCompanySlide();
    buildCultureSlide();
  });
}

function enableTyping() {
  const body = document.querySelector('.r6group__next__body');
  const paragraphs = readText();
  buildDom(paragraphs);
  observe();

  function readText() {
    const template = document.querySelector('.r6group__next__template');
    const text = template.content.textContent.trim();
    return text.replaceAll('\r', '').split('\n\n').map(paragraph => {
      return paragraph.split('\n').map(line => line.trim());
    });
  }

  function buildDom(paragraphs) {
    const fragment = new DocumentFragment();
    for (let paragraph of paragraphs) {
      const paragraphDiv = document.createElement('div');
      paragraphDiv.classList.add('r6group__next__paragraph');
      let delay = 0;
      for (let line of paragraph) {
        const lineDiv = document.createElement('div');
        lineDiv.classList.add('r6group__next__line');
        for (let char of line) {
          const span = document.createElement('span');
          span.innerHTML = char.replace(' ', '&nbsp;');
          span.style.animationDelay = `${delay}ms`;
          delay += 25;
          lineDiv.appendChild(span);
        }
        paragraphDiv.appendChild(lineDiv);
      }
      fragment.append(paragraphDiv);
    }
    body.append(fragment);
  }

  function observe() {
    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.isIntersecting && !entry.target.classList.contains('-typing')) {
          entry.target.classList.add('-typing');
        }
      }
    }, {
      rootMargin: '0% 0% -25%',
    });
    [...body.children].forEach(paragraph => observer.observe(paragraph));
  }
}

function buildCompanySlide() {
  const swiper = new Swiper ('.r6group__company__imagesContainer', {
    modules: [Autoplay, Navigation],
    speed: 800,
    spaceBetween: 12,
    slidesPerView: 'auto',
    loop: true,
    centeredSlides: true,
    initialSlide: 0,
    navigation: {
      prevEl: '.r6group__company__navigation.-prev',
      nextEl: '.r6group__company__navigation.-next',
    },
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      stopOnLastSlide: false
    },
    breakpoints: {
      768: {
        spaceBetween: 32,
      },
    },
  });        
}

function buildCultureSlide() {
  const swiper = new Swiper('.r6group__culture__bgsWrapper', {
    modules: [Autoplay],
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 80,
    initialSlide: 0,  // これがないと高速スクロールすることがある？
    autoplay: {
      delay: 0,
    },
    speed: 20000,
    allowTouchMove: false,
    breakpoints: {
      768: {
        spaceBetween: 200,
        speed: 30000,
      }
    },
  });
}