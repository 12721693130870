import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import useYouTubeApi from './useYouTubeApi';

let player = null;
let modal = null;
let onClose = null;

export default function() {
  useYouTubeApi(function() {
    if (!document.getElementById('l-movieModal__player')) {
      console.error('id="l-movieModal__player"の要素が必要');
    }

    player = new YT.Player('l-movieModal__player', {
      height: '360',
      width: '640',
      playerVars: {
        controls: 1,
        autoplay: 1,
        disablekb: 1,
        modestbranding: 1,
        enablejsapi: 1,
        iv_load_policy: 3,
        playsinline: 1,
        rel: 0,
      },
      events: {
        onReady: onPlayerReady
      },
    });

    function onPlayerReady(e) {
      e.target.mute();
      e.target.playVideo();
    }
  });

  modal = document.querySelector('.l-movieModal');
  const close = document.querySelector('.l-movieModal__close');

  close.addEventListener('click', hide);
  modal.addEventListener('click', e => {
    if (!e.target.closest('.l-movieModal__player')) {
      hide();
    }
  })

  return openMovieModal;
}

function show() {
  disableBodyScroll(modal);
  modal.classList.add('-show');
}

function hide() {
  player.pauseVideo();
  modal.classList.remove('-show');
  document.documentElement.style.scrollBehavior = 'auto';  // iOSでモーダルを閉じるときに、bodyが高速スクロールして戻ってくるのを一瞬で戻すようにする
  enableBodyScroll(modal);
  setTimeout(() => document.documentElement.style.scrollBehavior = '');
  onClose && onClose();
}

function openMovieModal(videoId, onCloseCallback) {
  show();
  onClose = onCloseCallback;
  player.loadVideoById(videoId);
}