/*
class="m-invew"が設定された要素が対象。
下記の方法で指定を行う

data-inview='{
  "from":"-inview-hidden",    // 初期化時にこのクラスが付与されます。
  "to":"-inview",             // イベント発火時にこのクラスが付与されます。省略した場合は"-inview"を指定したものとみなします。
  "delay": 0,                 // イベント発火時からクラス付与までの遅延
  "related":".related_item",  // イベント発火時に、このセレクタで要素を検索し、その要素のイベントも発火させます。
  "ratio":0.7,                // 画面のどの割合まで要素が来たときにイベントを発火させるか。省略した場合は0.7を指定したものとみなします。
  "passive": true,            // trueを指定した場合、スクロールではイベントが発火しなくなります。
  "sp": {}                    // spの場合、この設定で上記設定を上書きします。
}'
*/

window.addEventListener('DOMContentLoaded', () => {

  const listeners = {};

  const inviews = document.querySelectorAll('.m-inview');
  inviews.forEach((inview, index) => {
    inview.dataset.inviewIndex = index;
    let rawConfig = JSON.parse(inview.dataset.inview || '{}');

    const pcConfig = buildConfig({...rawConfig, ...rawConfig.pc});
    const spConfig = buildConfig({...rawConfig, ...rawConfig.sp});
    const listener = { inview, pcConfig, spConfig };
    decideMedia(listener);
    listeners[index] = listener;

    if (listener.config.from) inview.classList.add(listener.config.from);
  });

  function decideMedia(listener) {
    listener.config = (window.__MEDIA == 'PC') ? listener.pcConfig : listener.spConfig;
  }

  function buildConfig(config) {
    return {
      from: config.from,
      to: (config.to === undefined) ? '-inview' : config.to,
      ratio: config.ratio || 0.7,
      relatedSelector: config.related,
      delay: config.delay || 0,
      passive: config.passive,
    };
  }

  function onScroll() {
    const wh = window.innerHeight

    for (let index in listeners) {
      const {inview, config} = listeners[index];
      const top = inview.getBoundingClientRect().top

      if (!config.passive && wh * config.ratio > top) {
        // アニメーションを実行
        executeAnimation(inview, config);
      }
    }
  }

  function executeAnimation(inview, config) {
    if (inview.dataset.inviewCompleted === 'true') return;

    inview.dataset.inviewCompleted = 'true';
    setTimeout(() => {
      if (config.from) inview.classList.remove(config.from);
      inview.classList.add(config.to);
    }, config.delay);

    if (config.relatedSelector) {
      const nodes = document.querySelectorAll(config.relatedSelector);
      for (let node of nodes) {
        const listener = listeners[node.dataset.inviewIndex];
        if (listener) {
          executeAnimation(node, listener.config);
        }
      }
    }

    delete listeners[inview.dataset.inviewIndex];
  }

  window.addEventListener('load', onScroll);
  window.addEventListener('scroll', onScroll);

  window.addEventListener('custom:media', () => {
    for (let index in listeners) {
      decideMedia(listeners[index]);
    }
  });

  // iOS Safariで初めてページにアクセスしたとき、scrollイベントが数百ミリ秒遅延する問題のワークアラウンド
  let timer = null;
  window.addEventListener('load', () => {
    timer = setInterval(onScroll, 100);
  });
  window.addEventListener('scroll', stopTimer);
  function stopTimer() {
    clearInterval(timer);
    window.removeEventListener('scroll', stopTimer);
  }

  // Androidでloadが遅い件のワークアラウンド
  setTimeout(onScroll, 500);
});
