import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

function enableGnav() {
  const gnavOpenButton = document.querySelector('.l-gnav__modalOpen');
  const gnav = document.querySelector('.l-gnavModal');
  const inner = document.querySelector('.l-gnavModal__inner');  // スクロールする要素
  const close = document.querySelector('.l-gnavModal__close');

  function show() {
    gnav.classList.add('-open');
    document.documentElement.style.scrollBehavior = 'auto';  // iOSでモーダルを閉じるときに、bodyが高速スクロールして戻ってくるのを一瞬で戻すようにする
    disableBodyScroll(inner);
  }

  function hide() {
    gnav.classList.remove('-open');
    enableBodyScroll(inner);
    setTimeout(() => document.documentElement.style.scrollBehavior = '');
  }

  gnavOpenButton.addEventListener('click', show);
  close.addEventListener('click', hide);
  window.addEventListener('custom:media', e => {
    if (e.detail.media === 'PC') {
      hide();
    }
  });
}

enableGnav();