import Swiper, {Autoplay} from 'swiper';
// import {Autoplay} from 'swiper/modules';

export default function() {
  for (let slide of document.querySelectorAll('.l-ourBusinessSlide')) {
    build(slide);
  }
}

function build(container) {
  const reverse = container.dataset.reverse === 'true';

  const swiper = new Swiper(container, {
    modules: [Autoplay],
    loop: true,
    slidesPerView: 'auto',
    initialSlide: reverse ? -1 : 0,  // これがないと高速スクロールすることがある？
    autoplay: {
      delay: 0,
      reverseDirection: reverse,
    },
    speed: 3000,
    allowTouchMove: false,
    breakpoints: {
    },
  });
}