let initialized = false;
let callbacks = [];

export default function(callback) {
  if (!initialized) {
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    initialized = true;
  }

  callbacks.push(callback);

  window.onYouTubeIframeAPIReady = function() {
    for (let callback of callbacks) {
      callback();
    }
  }
}