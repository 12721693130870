import Swiper, {EffectCoverflow, Pagination, Autoplay} from 'swiper';
// import {EffectCoverflow, Pagination, Autoplay} from 'swiper/modules';
import enableMovieModal from '../module/enableMovieModal';

export default function(containerSelector, paginationSelector) {
  const openMovieModal = enableMovieModal();

  const swiper = new Swiper (containerSelector, {
    modules: [EffectCoverflow, Pagination, Autoplay],
    effect: 'coverflow',
    speed: 800,
    slidesPerView: 'auto',
    loop: true,
    centeredSlides: true,
    coverflowEffect: {
      rotate: 0,
      scale: 0.81,
      stretch: -66,
    },
    pagination: {
      el: paginationSelector,
      clickable: true
    },
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      stopOnLastSlide: false
    },
    on: {
      // init: swiper => swiper.slideNext(),  // initialSlideを指定しても初期スライドが最後のページになるので、無理やり送る
      click: onClick,
    },
  });

  function onClick(_, e) {
    const youtubeAnchor = e.target.closest('[data-movie-id]');
    if (!youtubeAnchor) return;

    swiper.autoplay.stop();

    const movieId = youtubeAnchor.dataset.movieId;
    openMovieModal(movieId, () => {
      const active = document.querySelector('.swiper-pagination-bullet-active');
      if (active) {
        active.classList.remove('swiper-pagination-bullet-active');
        setTimeout(() => {
          const bullets = document.querySelectorAll('.swiper-pagination-bullet');
          bullets[swiper.realIndex].classList.add('swiper-pagination-bullet-active');
        });
      }
      setTimeout(function(){
          swiper.autoplay.start();
      },900);
    });
  }
}