import Swiper, {Autoplay} from 'swiper';

export default function() {
  buildMainSlide();
  buildBgSlide();
}

function buildMainSlide() {
  let slideOnPaginatin = 1;

  let slidePerPage = 2;
  function calcSlidePerPage() {
    let nextSlidePerPage = null;
    const switchWidth = (window.__MEDIA === 'PC') ? 1175 : 635;
    if (document.documentElement.getBoundingClientRect().width <= switchWidth) {
      nextSlidePerPage = 1;
    } else {
      nextSlidePerPage = 2;
    }
    if (slidePerPage !== nextSlidePerPage) {
      slidePerPage = nextSlidePerPage;
      buildPagination();
      updateIndicator();
    }
  }
  calcSlidePerPage();
  window.addEventListener('resize', calcSlidePerPage);

  const container = document.querySelector('.l-people__interviews');
  const swiper = new Swiper(container, {
    slidesPerView: 'auto',
    spaceBetween: 15,
    speed: 500,
    on: {
      afterInit: setTimeout(() => {
        buildPagination();
        updateIndicator();
      }),
      progress: (_, progress) => updateIndicator(progress),  // 最後のスライドではactiveIndexなど各種indexが変化しないことがあるため、progressを使用
    },
    breakpoints: {
      768: {
        spaceBetween: 33,
      }
    },
  });

  function calcPageNumber() {
    return Math.ceil(swiper.slides.length / slideOnPaginatin) - (slidePerPage - slideOnPaginatin);
  }

  function buildPagination() {
    if (!swiper) return;
    const paginationInner = document.querySelector('.l-people__interviews__pagination__inner');
    const pageNumber = calcPageNumber();
    const bullets = [];
    for (let i = 0; i < pageNumber; i++) {
      const bullet = document.createElement('span');
      bullet.classList.add('l-people__interviews__pagination__bullet');
      bullet.addEventListener('click', () => {
        swiper.slideTo(i * slideOnPaginatin);
      });
      bullets.push(bullet);
    }
    paginationInner.replaceChildren(...bullets);
  }

  const indicator = document.querySelector('.l-people__interviews__pagination__indicator');
  function updateIndicator(progress) {
    if (!swiper) return;
    setTimeout(() => { // progressイベント時点ではactiveIndexが更新されていないため
      const pageNumber = calcPageNumber();
      const width = 100 / pageNumber;
      indicator.style.width = `${width}%`;

      const currentIndex = (progress === 1) ? swiper.slides.length - slidePerPage : swiper.activeIndex;
      const currentPage = Math.floor(currentIndex / slideOnPaginatin);  // 0 origin, activeIndexがページの先頭スライド以外を指している場合は次のページ（最終ページが半端だとそうなる）
      indicator.style.transform = `translateX(${currentPage * 100}%)`;
    }, 50);
  }
}

function buildBgSlide() {
  const swiper = new Swiper('.l-people__body__bgsWrapper', {
    modules: [Autoplay],
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 80,
    initialSlide: 0,  // これがないと高速スクロールすることがある？
    autoplay: {
      delay: 0,
    },
    speed: 30000,
    allowTouchMove: false,
    breakpoints: {
      768: {
        spaceBetween: 200,
      }
    },
  });
}