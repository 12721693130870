import Accordion from 'accordion-js';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import buildOurBusinessSlide from '../module/buildOurBusinessSlide';
import buildOurBusinessModal from '../module/buildOurBusinessModal';
import buildMoviesSlide from '../module/buildMoviesSlide';
import buildPeopleSlide from '../module/buildPeopleSlide';
import trackMouseOnHover from '../module/trackMouseOnHover';
import enableOffer from '../module/enableOffer';

if (document.body.classList.contains('r6students')) {
  window.addEventListener('DOMContentLoaded', () => {
    buildOurBusinessSlide();
    buildOurBusinessModal();
    buildPeopleSlide();
    trackMouseOnHover('.l-ourBusiness__body', '.l-ourBusiness__body__cursor');
    enableOffer();
    buildMoviesSlide('.r6students__movies__container', '.r6students__movies__pagination');
    buildAccordion();
    enableAbout();
  });

  function buildAccordion() {
    new Accordion('.r6students__req__accordions', {
      showMultiple: true,
      openOnInit: [0],
    });
  }

  function enableAbout() {
    const icons = document.querySelector('.r6students__kv__icons');
    const about = document.querySelector('#modal-about');
    const close = about.querySelector('.btn-close');

    function show() {
      about.classList.add('show');
      disableBodyScroll(about);
    }

    function hide() {
      about.classList.remove('show');
      document.documentElement.style.scrollBehavior = 'auto';  // iOSでモーダルを閉じるときに、bodyが高速スクロールして戻ってくるのを一瞬で戻すようにする
      enableBodyScroll(about);
      setTimeout(() => document.documentElement.style.scrollBehavior = '');
    }

    icons.addEventListener('click', show);
    close.addEventListener('click', hide);
    about.addEventListener('click', e => {
      if (!e.target.closest('.modal-wrap')) {
        hide();
      }
    });
  }
}