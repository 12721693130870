import enableMovieModal from '../module/enableMovieModal';

if (document.body.classList.contains('r6newsDetail')) {
  window.addEventListener('DOMContentLoaded', () => {
    const openMovieModal = enableMovieModal();
    prepareMovieModal(openMovieModal);
  });
}

function prepareMovieModal(openMovieModal) {
  const movies = document.querySelectorAll('[data-movie-id]');
  for (let movie of movies) {
    movie.addEventListener('click', () => {
      openMovieModal(movie.dataset.movieId);
    });
  }
}