// 1.グロナビのモード
// 1-1. viewport上部固定モード（初期状態）
//    初期〜アンカー接触
// 1-1-1. ONトリガー（行き）: 初期
// 1-1-2. ONトリガー（戻り）: KV下部 > グロナビ下部
//        アンカーエリアとグロナビのIntersection（intersecting=false）
// 1-2. KV下部固定モード
//    アンカー接触〜画面外に消える
// 1-2-1. ONトリガー（行き）: KV下部 <= グロナビ下部
//        アンカーエリアとグロナビのIntersection（intersecting=true）
// 1-2-2. ONトリガー（戻り）: KV下部 > グロナビ下部（1と同条件のため戻りはこのモードに移行しない）
// 1-3. スクロール連動モード
//    画面外に消える〜
//    上スクロールでアンカーとともに下がる
//    下スクロールでアンカーとともに上がる
// 1-3-1. ONトリガー（行き）: グロナビ下部 <= 画面上部
//        viewportとグロナビのIntersection（intersecting=false）
// 1-3-2. ONトリガー（戻り）: なし
//
// 2. アンカーのモード
// 2-1. アンカーエリア固定モード
//    初期〜画面上部接触
// 2-1-1. ONトリガー（行き）: 初期
// 2-1-2. ONトリガー（戻り）: アンカー上部 <= アンカーエリア上部
//      アンカーとアンカーエリアのIntersection（Intersecting=true）
// 2-2. スクロール連動モード
//    画面上部接触〜
//    上スクロールで下がる
//    下スクロールで上がる
// 2-2-1. ONトリガー（行き）: アンカー上部 <= 画面上部
//        アンカーと画面上部のIntersection（intersecting=true）
// 2-2-2. ONトリガー（戻り）: なし

function trackNavigation() {
  if (document.querySelector('.m-anchor')) {
    trackNavigationWithAnchor();
  } else {
    trackNavigationWithoutAnchor();
  }
}

function gnavHeight() {
  return (window.__MEDIA === 'PC') ? GNAV_HEIGHT : GNAV_HEIGHT_SP;
}

function trackNavigationWithAnchor() {

  // アンカーエリア
  const anchorContainer = document.querySelector('.m-anchor__container');
  // アンカー
  const anchor = document.querySelector('.m-anchor__inner');

  // モード
  let mode = null;
  function changeMode(newMode) {
    mode = newMode;
    document.documentElement.dataset.navMode = mode;
    const isScroll = mode === MODE.SCROLL;
    setTimeout(() => {
      document.documentElement.dataset.navScrollAnimation = isScroll;
    });
  }
  changeMode(MODE.TOP_FIXED);

  // ↓ position: fixedの要素はIntersectionObserverがおかしくなるっぽいのでscrollイベントで代用
  // 1-1. viewport上部固定モード（初期状態）
  // 1-1-2. ONトリガー（戻り）: KV下部 > グロナビ下部
  //        アンカーエリアとグロナビのIntersection（intersecting=false）
  function observeTopFixed(position) {
    const rect = anchorContainer.getBoundingClientRect();
    if ((mode === MODE.SCROLL || mode === MODE.KV_BOTTOM_FIXED) && rect.y >= gnavHeight()) {
      return MODE.TOP_FIXED;
    }
    return null;
  }

  // ↓ position: fixedの要素はIntersectionObserverがおかしくなるっぽいのでscrollイベントで代用
  // 1-2. KV下部固定モード
  // 1-2-1. ONトリガー（行き）: KV下部 <= グロナビ下部
  //        アンカーエリアとグロナビのIntersection（intersecting=true）
  function observerKvBottomFixed(position) {
    const rect = anchorContainer.getBoundingClientRect();
    if (mode === MODE.TOP_FIXED && rect.y <= gnavHeight()) {
      return MODE.KV_BOTTOM_FIXED;
    }
    return null;
  }

  // 1-3. スクロール連動モード
  // 1-3-1. ONトリガー（行き）: グロナビ下部 <= 画面上部
  //        viewportとグロナビのIntersection（intersecting=false）
  function observerScroll(position) {
    const rect = anchorContainer.getBoundingClientRect();
    if (mode === MODE.KV_BOTTOM_FIXED && rect.y <= 0) {
      return MODE.SCROLL;
    }
    return null;
  }

  let prevPosition = 0;
  window.addEventListener('scroll', () => {
    const position = window.scrollY;
    const direction = (prevPosition < position) ? 'down' : 'up';
    document.documentElement.dataset.scrollDirection = direction;

    let nextMode = observerKvBottomFixed(position);
    nextMode = observeTopFixed(position) || nextMode;
    nextMode = observerScroll(position) || nextMode;
    if (nextMode) changeMode(nextMode);

    prevPosition = position;
  });
}

function trackNavigationWithoutAnchor() {

  const border = document.querySelector('[data-tracking-nav-border]');
  if (!border) {
    console.error('data-tracking-nav-borderを持った要素が必要');
    return;
  }

  let prevPosition = 0;
  window.addEventListener('scroll', () => {
    const position = window.scrollY;
    const direction = (prevPosition < position) ? 'down' : 'up';
    document.documentElement.dataset.scrollDirection = direction;

    const rect = border.getBoundingClientRect();
    if (rect.bottom < gnavHeight()) {
      document.documentElement.dataset.navModeWithoutAnchor = "scroll";
    } else {
      document.documentElement.dataset.navModeWithoutAnchor = "fixed";
    }

    prevPosition = position;
  });
}

const MODE = {
  TOP_FIXED: 'topFixed',
  KV_BOTTOM_FIXED: 'kvBottomFixed',
  SCROLL: 'scroll',
}

// gnavの設定は_setting.scss内にもあるので注意
const GNAV_HEIGHT = 140;
const GNAV_HEIGHT_SP = 90;

trackNavigation();