const mmMin = window.matchMedia(`(min-width: ${process.env.BREAK_MIN}px)`);

function updateMedia(m) {
  window.__MEDIA = m.matches ? 'PC' : 'SP';
}

updateMedia(mmMin);

mmMin.addEventListener('change', (m) => {
  updateMedia(m);
  const event = new CustomEvent('custom:media', {
    detail: {
      media: window.__MEDIA,
    },
  });
  window.dispatchEvent(event);
});