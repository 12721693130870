let observers = [];
let lastX = 0;
let lastY = 0;

class Observer {
  constructor(container, cursor) {
    this.container = container;
    this.cursor = cursor;
  }

  updateCursor() {
    const rect = this.container.getBoundingClientRect();
    if (this.isCursorOnContainer(rect)) {
      this.showCursor();
      this.cursor.style.transform = 'translate(' + (lastX - rect.x) + 'px, ' + (lastY - rect.y) + 'px)';
    } else {
      this.hideCursor();
    }
  }

  showCursor() {
    this.cursor.classList.add('-show');
  }

  hideCursor() {
    this.cursor.classList.remove('-show');
  }

  isCursorOnContainer(rect) {
    return rect.x <= lastX &&
      lastX <= rect.x + rect.width &&
      rect.y <= lastY &&
      lastY <= rect.y + rect.height;
  }
}

export default function(containerSelector, cursorSelector) {
  if (observers.length === 0) {
    document.documentElement.addEventListener('mouseenter', () => {
      observers.forEach(observer => observer.updateCursor());
    });
    document.documentElement.addEventListener('mouseleave', () => {
      observers.forEach(observer => observer.hideCursor());
    });
    document.documentElement.addEventListener('mousemove', e => {
      lastX = e.clientX;
      lastY = e.clientY;
      observers.forEach(observer => observer.updateCursor());
    });
    window.addEventListener('scroll', () => {
      observers.forEach(observer => observer.updateCursor());
    });
  }

  const container = document.querySelector(containerSelector);
  const cursor = document.querySelector(cursorSelector);
  observers.push(new Observer(container, cursor));
}