import buildOurBusinessSlide from '../module/buildOurBusinessSlide';
import buildOurBusinessModal from '../module/buildOurBusinessModal';
import buildPeopleSlide from '../module/buildPeopleSlide';
import trackMouseOnHover from '../module/trackMouseOnHover';
import enableOffer from '../module/enableOffer';

if (document.body.classList.contains('r6top')) {
  window.addEventListener('DOMContentLoaded', () => {
    // buildKvMovie();
    buildOurBusinessSlide();
    buildOurBusinessModal();
    buildPeopleSlide();
    trackMouseOnHover('.l-ourBusiness__body', '.l-ourBusiness__body__cursor');
    trackMouseOnHover('.r6top__recruit__positions', '.r6top__recruit__positions__cursor');
    enableOffer();
    switchVideo();
  });
}

function switchVideo() {
  const video = document.querySelector('.r6top__kv__movie video');

  function addSourceToVideo(src) {
    var source = document.createElement('source');
    source.src = src;
    source.type = 'video/mp4';
    video.replaceChildren(source);
    video.load();
    video.play();
  }

  function whichSizeVideo() {
    if (window.__MEDIA === 'PC') {
      addSourceToVideo(video.dataset.pcVid);
    } else {
      addSourceToVideo(video.dataset.spVid);
    }
  }

  whichSizeVideo();
  window.addEventListener('custom:media', whichSizeVideo);
}

function buildKvMovie() {
  var tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  let player = null;
  window.onYouTubeIframeAPIReady = function() {
    player = new YT.Player('r6topKvBgMovie', {
      height: '360',
      width: '640',
      videoId: 'SJOl8E1Q1g8',
      playerVars: {
        controls: 0,
        autoplay: 1,
        disablekb: 1,
        modestbranding: 1,
        enablejsapi: 1,
        iv_load_policy: 3,
        playsinline: 1,
        rel: 0
      },
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange
      }
    });
  }

  function onPlayerReady(event) {
    event.target.mute();
    event.target.playVideo();
  }

  function onPlayerStateChange(event) {
    var ytStatus = event.target.getPlayerState();
    if (ytStatus == YT.PlayerState.ENDED) {
      player.mute();
      player.playVideo();
    }
  }
}