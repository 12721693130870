import buildOurBusinessSlide from '../module/buildOurBusinessSlide';
import buildOurBusinessModal from '../module/buildOurBusinessModal';
import buildPeopleSlide from '../module/buildPeopleSlide';
import trackMouseOnHover from '../module/trackMouseOnHover';

if (document.body.classList.contains('r6career')) {
  window.addEventListener('DOMContentLoaded', () => {
    buildOurBusinessSlide();
    buildOurBusinessModal();
    buildPeopleSlide();
    trackMouseOnHover('.l-ourBusiness__body', '.l-ourBusiness__body__cursor');
  });
}